<template>
  <div v-if="!this.$func.checkPermission('รายการคืนยอดเสีย')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <card>
          <div slot="header">
            <h3 class="m-0">รายการยอดได้เสีย</h3>
          </div>
          <div class="row">
            <div class="col-sm-3 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="paginationHistoryWinloss.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in paginationHistoryWinloss.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-3 offset-sm-6 d-flex mb-3">
              <el-input
                type="search"
                class="my-auto mr-2"
                placeholder="ค้นหา"
                v-model="searchQueryHistoryWinloss"
                aria-controls="datatables"
              />
              <el-button
                type="danger"
                size="default"
                @click="getHistoryWinloss()"
                :loading="loading"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                :data="tableDataHistoryWinloss"
                border
                v-loading="loading"
              >
                <el-table-column :min-width="20" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="40" label="Username">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="40" label="Betflix">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D5 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="40" label="ชื่อ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"></span>
                  </template>
                </el-table-column>

                <el-table-column :min-width="20" label="ยอดฝาก">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D4).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    ></span>
                  </template> </el-table-column
                ><el-table-column :min-width="60" label="ยอดได้ / เสีย">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D6 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="60" label="ยอดเสียที่ได้">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D7 : ''"></span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ fromHistoryWinloss + 1 }} to
                {{ toHistoryWinloss }} of {{ totalHistoryWinloss }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="paginationHistoryWinloss.currentPage"
              :per-page="paginationHistoryWinloss.perPage"
              :total="paginationHistoryWinloss.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <div slot="header">
            <h3 class="m-0">ประวัติคืนยอดเสีย</h3>
          </div>
          <div class="row">
            <div class="col-sm-3 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="paginationHistoryLose.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in paginationHistoryLose.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-3 offset-sm-6 d-flex mb-3">
              <el-input
                type="search"
                class="my-auto mr-2"
                placeholder="ค้นหา"
                v-model="searchQueryHistoryLose"
                aria-controls="datatables"
              />

              <el-button
                type="danger"
                size="default"
                @click="getHistoryLose()"
                :loading="loadingLose"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                :data="tableDataHistoryLose"
                border
                v-loading="loadingLose"
              >
                <el-table-column :min-width="20" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="60" label="วัน/เวลา">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="40" label="Username">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="60" label="Betflix">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D9 : ''"></span>
                  </template>
                </el-table-column>

                <el-table-column :min-width="60" label="ชื่อ-สกุล">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D6 : ''"></span>
                  </template> </el-table-column
                ><el-table-column :min-width="60" label="ยอดที่ได้รับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"></span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="40" label="สถานะ">
                  <template v-slot="props">
                    <span
                      v-html="props ? props.row.D5 : ''"
                      class="badge badge-success"
                    ></span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ fromHistoryLose + 1 }} to {{ toHistoryLose }} of
                {{ totalHistoryLose }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="paginationHistoryLose.currentPage"
              :per-page="paginationHistoryLose.perPage"
              :total="paginationHistoryLose.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Button, Select, Option } from "element-ui";

import { Pagination as LPagination } from "src/components/index";
import axios from "axios";

export default {
  components: {
    LPagination,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      loading: true,
      loadingLose: true,
      loadingTimeout: true,
      paginationHistoryWinloss: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 30, 50],
        total: 0,
      },
      paginationHistoryLose: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 30, 50],
        total: 0,
      },
      searchQueryHistoryWinloss: "",
      searchQueryHistoryLose: "",
      tableDataHistoryWinloss: [],
      tableDataHistoryLose: [],
      timeHistoryWinloss: "",
      timeHistorylose: "",
    };
  },
  computed: {
    toHistoryWinloss() {
      let highBound =
        this.fromHistoryWinloss + this.paginationHistoryWinloss.perPage;
      if (this.totalHistoryWinloss < highBound) {
        highBound = this.totalHistoryWinloss;
      }
      return highBound;
    },
    fromHistoryWinloss() {
      this.getHistoryWinloss();
      return (
        this.paginationHistoryWinloss.perPage *
        (this.paginationHistoryWinloss.currentPage - 1)
      );
    },
    totalHistoryWinloss() {
      return this.paginationHistoryWinloss.total;
    },

    toHistoryLose() {
      let highBound = this.fromHistoryLose + this.paginationHistoryLose.perPage;
      if (this.totalHistoryLose < highBound) {
        highBound = this.totalHistoryLose;
      }
      return highBound;
    },
    fromHistoryLose() {
      this.getHistoryLose();
      return (
        this.paginationHistoryLose.perPage *
        (this.paginationHistoryLose.currentPage - 1)
      );
    },
    totalHistoryLose() {
      return this.paginationHistoryLose.total;
    },
  },
  methods: {
    async getHistoryWinloss() {
      this.loading = true;
      let offset =
        this.paginationHistoryWinloss.perPage *
        (this.paginationHistoryWinloss.currentPage - 1);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/historywinloss?search=${this.searchQueryHistoryWinloss}&sort&order=desc&offset=${offset}&limit=${this.paginationHistoryWinloss.perPage}&action&start_date=&end_date=`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
        timeout: 10000,
      };

      let timeLoad = 500;
      if (this.loadingTimeout === true) {
        timeLoad = 500;
      }

      clearTimeout(this.timeHistoryWinloss);

      this.timeHistoryWinloss = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            this.loading = false;
            this.loadingTimeout = false;
            this.tableDataHistoryWinloss = [];
            let data = res.data;
            if (data.res_code === "00") {
              this.tableDataHistoryWinloss = data.res_result.rows;
              this.paginationHistoryWinloss.total = data.res_result.total;
            }
          })
          .catch((error) => {
            this.loading = false;
            this.loadingTimeout = false;
          });
      }, timeLoad);
    },
    async getHistoryLose() {
      this.loadingLose = true;
      let offset =
        this.paginationHistoryLose.perPage *
        (this.paginationHistoryLose.currentPage - 1);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/history_lose?search=${this.searchQueryHistoryLose}&sort&order=desc&offset=${offset}&limit=${this.paginationHistoryLose.perPage}&action&start_date=&end_date=`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
        timeout: 10000,
      };

      clearTimeout(this.timeHistoryLose);

      this.timeHistoryLose = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            this.loadingLose = false;
            this.tableDataHistoryLose = [];
            let data = res.data;
            if (data.res_code === "00") {
              this.tableDataHistoryLose = data.res_result.rows;
              this.paginationHistoryLose.total = data.res_result.total;
            }
          })
          .catch((error) => {
            this.loadingLose = false;
          });
      }, 500);
    },
  },
  mounted() {
    this.getHistoryWinloss();
    this.getHistoryLose();
  },
  watch: {
    searchQueryHistoryWinloss(newSearch, oldSearch) {
      this.searchQueryHistoryWinloss = newSearch;
    },
    searchQueryHistoryLose(newSearch, oldSearch) {
      this.searchQueryHistoryLose = newSearch;
    },
  },
};
</script>
<style></style>
